import React, { useRef, useState, Component, useEffect } from "react";

/* Img  ======================================================== */

import defaultTwitter from "../assets/social/default_twitter.png";
import defaultDiscord from "../assets/social/default_discord.png";
import defaultMagiceden from "../assets/social/default_magiceden.png";
import defaultOpensea from "../assets/social/default_opensea.png";

/* Hover  ======================================================== */
import hoverTwitter from "../assets/social/hover_twitter.png";
import hoverDiscord from "../assets/social/hover_discord.png";
import hoverMagicEden from "../assets/social/hover_magiceden.png";
import hoverOpensea from "../assets/social/hover_opensea.png";

const Socials = () => {
  return (
    <>
      {/* Footer  ======================================================== */}

      <footer className="footer">
        <div className="inner">
          <a href="https://twitter.com/BeatBoysXYZ" target="_blank">
            <img
              src={defaultTwitter}
              onMouseOver={(e) => (e.currentTarget.src = hoverTwitter)}
              onMouseOut={(e) => (e.currentTarget.src = defaultTwitter)}
              loading="lazy"
              alt="twitter"
            />
          </a>
          <a href="#" target="_blank">
            <img
              src={defaultDiscord}
              onMouseOver={(e) => (e.currentTarget.src = hoverDiscord)}
              onMouseOut={(e) => (e.currentTarget.src = defaultDiscord)}
              loading="lazy"
              alt="discord"
            />
          </a>
          <a href="#" target="_blank">
            <img
              src={defaultMagiceden}
              onMouseOver={(e) => (e.currentTarget.src = hoverMagicEden)}
              onMouseOut={(e) => (e.currentTarget.src = defaultMagiceden)}
              loading="lazy"
              alt="magiceden"
            />
          </a>
          <a href="#" target="_blank">
            <img
              src={defaultOpensea}
              onMouseOver={(e) => (e.currentTarget.src = hoverOpensea)}
              onMouseOut={(e) => (e.currentTarget.src = defaultOpensea)}
              loading="lazy"
              alt="opensea"
            />
          </a>
        </div>
      </footer>

      <div className="hidden-img">
        <a href="https://twitter.com/BeatBoysXYZ" target="_blank">
          <img src={hoverTwitter} loading="lazy" alt="twitter" />
        </a>
        <a href="#" target="_blank">
          <img src={hoverDiscord} loading="lazy" alt="discord" />
        </a>
        <a href="#" target="_blank">
          <img src={hoverMagicEden} loading="lazy" alt="magiceden" />
        </a>
        <a href="#" target="_blank">
          <img src={hoverOpensea} loading="lazy" alt="opensea" />
        </a>
      </div>
    </>
  );
};

export default Socials;
