import React, { useRef, useState, Component, useEffect } from "react";

import Beat from "../beat";

/* Img  ======================================================== */
import polaroidBlank from "../../assets/blankPolaroid.png";
import swiperLeftOuter from "../../assets/swiper-paginate-outer-left.png";
import swiperLeftInner from "../../assets/swiper-paginate-inner-left.png";
import swiperRightOuter from "../../assets/swiper-paginate-outer-right.png";
import swiperRightInner from "../../assets/swiper-paginate-inner-right.png";
import JoinDiscordUp from "../../assets/followtheboysup.png";
import JoinDiscordDown from "../../assets/followtheboysdown.png";
import beatboy1 from "../../assets/beatboys/beatboy1.gif";
import beatboy2 from "../../assets/beatboys/beatboy2.gif";
import beatboy3 from "../../assets/beatboys/beatboy3.gif";
import beatboy4 from "../../assets/beatboys/beatboy4.gif";
import beatboy5 from "../../assets/beatboys/beatboy5.gif";
import beatboy6 from "../../assets/beatboys/beatboy6.gif";
import beatboy7 from "../../assets/beatboys/beatboy7.gif";
import beatboy8 from "../../assets/beatboys/beatboy8.gif";

/* Beats  ======================================================== */
import audio1 from "../../assets/sound/beat1.mp3";
import audio2 from "../../assets/sound/beat2.mp3";
import audio3 from "../../assets/sound/beat3.mp3";
import audio4 from "../../assets/sound/beat4.mp3";
import audio5 from "../../assets/sound/beat5.mp3";
import audio6 from "../../assets/sound/beat6.mp3";
import audio7 from "../../assets/sound/beat7.mp3";
import audio8 from "../../assets/sound/beat8.mp3";

/* Third party  ======================================================== */
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, {
  EffectFade,
  EffectCards,
  Pagination,
  Navigation,
} from "swiper";
SwiperCore.use([EffectFade, EffectCards, Pagination, Navigation]);

const Landing = () => {
  const [beat1, setBeat1] = useState(false);
  const [beat2, setBeat2] = useState(false);
  const [beat3, setBeat3] = useState(false);
  const [beat4, setBeat4] = useState(false);
  const [beat5, setBeat5] = useState(false);
  const [beat6, setBeat6] = useState(false);
  const [beat7, setBeat7] = useState(false);
  const [beat8, setBeat8] = useState(false);

  return (
    <section className="landing-section">
      <div className="landing-wrapper">
        {/* Gallery  ======================================================== */}
        <div className="landingSwiper">
          <div>
            <div className="swiper-navigation-wrapper">
              <div className="swiper-btn-wrapper">
                <img
                  className="outer"
                  src={swiperLeftOuter}
                  srcSet={swiperLeftOuter}
                  loading="lazy"
                  alt="prev"
                />
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="prev inner"
                  src={swiperLeftInner}
                  srcSet={swiperLeftInner}
                  loading="lazy"
                  alt="prev"
                />
              </div>
              <div className="swiper-btn-wrapper">
                <img
                  className="outer"
                  src={swiperRightOuter}
                  srcSet={swiperRightOuter}
                  loading="lazy"
                  alt="next"
                />
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="next inner"
                  src={swiperRightInner}
                  srcSet={swiperRightInner}
                  loading="lazy"
                  alt="next"
                />
              </div>
            </div>

            <Swiper
              effect={"cards"}
              grabCursor={true}
              loop={false}
              slidesPerGroup={1}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              modules={[EffectCards, Pagination]}
              className="mySwiper"
              onSlideChange={() => {
                setBeat1(false);
                setBeat2(false);
                setBeat3(false);
                setBeat4(false);
                setBeat5(false);
                setBeat6(false);
                setBeat7(false);
                setBeat8(false);
              }}
            >
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy1}
                  srcSet={beatboy1}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio1} playing={beat1} setPlaying={setBeat1} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy2}
                  srcSet={beatboy2}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio2} playing={beat2} setPlaying={setBeat2} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy3}
                  srcSet={beatboy3}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio3} playing={beat3} setPlaying={setBeat3} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy4}
                  srcSet={beatboy4}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio4} playing={beat4} setPlaying={setBeat4} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy5}
                  srcSet={beatboy5}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio5} playing={beat5} setPlaying={setBeat5} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy6}
                  srcSet={beatboy6}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio6} playing={beat6} setPlaying={setBeat6} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy7}
                  srcSet={beatboy7}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio7} playing={beat7} setPlaying={setBeat7} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy8}
                  srcSet={beatboy8}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio8} playing={beat8} setPlaying={setBeat8} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <footer>
          <a href="https://twitter.com/BeatBoysXYZ" target="_blank">
            <img
              src={JoinDiscordUp}
              onMouseOver={(e) => (e.currentTarget.src = JoinDiscordDown)}
              onMouseOut={(e) => (e.currentTarget.src = JoinDiscordUp)}
              loading="lazy"
              alt="Discord"
            />
          </a>
        </footer>
      </div>

      <div className="hidden-img">
        <img src={JoinDiscordDown} loading="lazy" alt="Discord" />
      </div>
    </section>
  );
};

export default Landing;
