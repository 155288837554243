import React, { useState, useEffect } from "react";
import Loader from "./components/loader";
import Header from "./components/header";
import Landing from "./components/pre-mint/landing";
import Video from "./components/video";
import Collection from "./components/collection";
import Story from "./components/story";
import Roadmap from "./components/roadmap";
import Team from "./components/team";
import Socials from "./components/footer";
import loader from "./assets/Loader.gif";

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  !loaded
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  !loaded
    ? (document.body.style.background = "black")
    : (document.body.style.background = "#D4902F");

  return (
    <div>
      <div
        style={{
          opacity: !loaded ? "1" : "0",
          pointerEvents: !loaded ? "auto" : "none",
        }}
        className="loader-wrapper"
      >
        <div className="loader-container">
          <img src={loader} srcSet={loader} loading="lazy" alt="" />
        </div>
      </div>

      <div className="App">
        <Header />
        <Landing />
        <Video />
        <Collection />
        <Story />
        <Team />
        <Socials />
      </div>
    </div>
  );
}

export default App;
