import React, { useRef, useState, Component, useEffect } from "react";

/* Img  ======================================================== */
import polaroidYessam from "../assets/team/YessamPolaroid.png";
import polaroidJayme from "../assets/team/JaymePolaroid.png";
import polaroidWAGMI from "../assets/team/wagmiPolaroid.png";
import yessamBlurb from "../assets/team/yessam_blurb.png";
import jaymeBlurb from "../assets/team/jayme_blurb.png";
import wagmiBlurb from "../assets/team/wagmi_blurb.png";
import banner from "../assets/team-banner.png";
import team from "../assets/team.png";

const Team = () => {
  return (
    <>
      {/* Banner 1  ======================================================== */}
      <img
        className="banner"
        src={banner}
        srcSet={banner}
        loading="lazy"
        alt="team banner"
      />

      <section className="team-section">
        {/* Team Section  ======================================================== */}
        <div className="team-wrapper ">
          <div>
            <img
              className="title"
              src={team}
              srcSet={team}
              loading="lazy"
              alt="team"
            />
            <div className="team-container">
              <div className="team-polaroid-wrapper">
                <a href="https://twitter.com/YessamBeats">
                  <img
                    src={polaroidYessam}
                    srcSet={polaroidYessam}
                    loading="lazy"
                    alt="Yessam beatboy"
                  />
                </a>
                <img
                  src={yessamBlurb}
                  srcSet={yessamBlurb}
                  loading="lazy"
                  alt="Yessam beatboy"
                />
              </div>
              <div className="team-polaroid-wrapper">
                <img
                  src={polaroidJayme}
                  srcSet={polaroidJayme}
                  loading="lazy"
                  alt="Jayme beatboy"
                />
                <img
                  src={jaymeBlurb}
                  srcSet={jaymeBlurb}
                  loading="lazy"
                  alt="Yessam beatboy"
                />
              </div>
              {/* <div className="team-polaroid-wrapper">
              <a href="https://twitter.com/WAGMIBeach">
                <img src={polaroidWAGMI} srcSet={polaroidWAGMI} loading="lazy" alt="WAGMI Beach beatboy" />
              </a>
              <img src={wagmiBlurb} srcSet={wagmiBlurb} loading="lazy" alt="Yessam beatboy" />
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
