import React, { useRef, useState, Component, useEffect } from "react";

/* Img  ======================================================== */

import banner1 from "../assets/video-banner1.png";
import banner2 from "../assets/video-banner2.png";
import videocover from "../assets/video-cover.png";
import play from "../assets/video-play.png";
import video from "../assets/glaciers_of_ice_bb_1.mp4"

/* Third party  ======================================================== */
import ReactPlayer from 'react-player'


const Video = () => {

 // Index of current video that is in ReactPlayer
 const [index, setIndex] = React.useState(0);

 const playerRef = React.useRef(null);

  // Keeps video in light mode //
  React.useEffect(() => {
    if (playerRef) {
      // This is the key to keeping the player in light mode
      playerRef.current.showPreview();
    }
    // If the video url changes, playerRef puts the player back in light mode
  }, [index]);

  return (
    <>
       {/* Banner 1  ======================================================== */}
       <img className="banner" src={banner1} srcSet={banner1} loading="lazy" alt="video banner" />
    <section className="video-section" >
     

         {/* Video Section  ======================================================== */}
        <div className="video-wrapper">
        
        <ReactPlayer
          url={video}
          width="100%"
          height="100%"
          playing
          controls={true}
          ref={playerRef}
          playIcon={<img className="video-btn" src={play} srcSet={play} loading="lazy" alt="play icon" />}
          light={videocover}
        />

        </div>

     
    </section>
       {/* Banner 2  ======================================================== */}
       <img className="banner" src={banner2} srcSet={banner2} loading="lazy" alt="video banner" />
       </>
  );
};

export default Video;
