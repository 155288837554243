import React, { useRef, useState, Component, useEffect } from "react";

import Beat from "./beat";

/* Img  ======================================================== */
import polaroidBlank from "../assets/blankPolaroid.png";
import swiperLeftOuter from "../assets/swiper-paginate-outer-left.png";
import swiperLeftInner from "../assets/swiper-paginate-inner-left.png";
import swiperRightOuter from "../assets/swiper-paginate-outer-right.png";
import swiperRightInner from "../assets/swiper-paginate-inner-right.png";
import title from "../assets/collection_title.png";
import collection1 from "../assets/collection1.png";
import collection2 from "../assets/collection2.png";
import beatboy1 from "../assets/beatboys/beatboy1.gif";
import beatboy2 from "../assets/beatboys/beatboy2.gif";
import beatboy3 from "../assets/beatboys/beatboy3.gif";
import beatboy4 from "../assets/beatboys/beatboy4.gif";
import beatboy5 from "../assets/beatboys/beatboy5.gif";
import beatboy6 from "../assets/beatboys/beatboy6.gif";
import beatboy7 from "../assets/beatboys/beatboy7.gif";
import beatboy8 from "../assets/beatboys/beatboy8.gif";
import drums from "../assets/Drums.png";
import bass from "../assets/Bass.png";
import guitar from "../assets/Guitar.png";
import vocal from "../assets/Vocal.png";
import eight0eight from "../assets/808.png";
import loop from "../assets/Loop.png";

/* Beats  ======================================================== */
import audio1 from "../assets/sound/beat1.mp3";
import audio2 from "../assets/sound/beat2.mp3";
import audio3 from "../assets/sound/beat3.mp3";
import audio4 from "../assets/sound/beat4.mp3";
import audio5 from "../assets/sound/beat5.mp3";
import audio6 from "../assets/sound/beat6.mp3";
import audio7 from "../assets/sound/beat7.mp3";
import audio8 from "../assets/sound/beat8.mp3";

/* Third party  ======================================================== */

import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, {
  EffectFade,
  EffectCards,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper";
SwiperCore.use([EffectFade, EffectCards, Pagination, Navigation, Autoplay]);

const Collection = () => {
  const [beat1, setBeat1] = useState(false);
  const [beat2, setBeat2] = useState(false);
  const [beat3, setBeat3] = useState(false);
  const [beat4, setBeat4] = useState(false);
  const [beat5, setBeat5] = useState(false);
  const [beat6, setBeat6] = useState(false);
  const [beat7, setBeat7] = useState(false);
  const [beat8, setBeat8] = useState(false);

  return (
    <section className="collection-section">
      {/* Collection Section  ======================================================== */}
      <div className="collection-wrapper">
        <div className="collection-var">
          {/* <img className="title" src={title} srcSet={title} loading="lazy" alt="Title" /> */}
          <img
            className="description1"
            src={collection1}
            srcSet={collection1}
            loading="lazy"
            alt="Description"
          />
          <img
            className="description2"
            src={collection2}
            srcSet={collection2}
            loading="lazy"
            alt="Description"
          />
        </div>
        <div className="marquee-mobile">
          <Marquee
            speed={75}
            gradient={false}
            pauseOnHover={false}
            pauseOnClick={false}
          >
            <div className="collection-marquee">
              <img src={drums} srcSet={drums} loading="lazy" alt="Drums" />
              <img src={bass} srcSet={bass} loading="lazy" alt="Bass" />
              <img src={guitar} srcSet={guitar} loading="lazy" alt="Guitar" />
              <img src={vocal} srcSet={vocal} loading="lazy" alt="Vocal" />
              <img
                src={eight0eight}
                srcSet={eight0eight}
                loading="lazy"
                alt="808"
              />
              <img src={loop} srcSet={loop} loading="lazy" alt="loop" />
              <img src={drums} srcSet={drums} loading="lazy" alt="drums" />
              <img src={bass} srcSet={bass} loading="lazy" alt="bass" />
              <img src={guitar} srcSet={guitar} loading="lazy" alt="guitar" />
              <img src={vocal} srcSet={vocal} loading="lazy" alt="vocal" />
              <img
                src={eight0eight}
                srcSet={eight0eight}
                loading="lazy"
                alt="808"
              />
              <img src={loop} srcSet={loop} loading="lazy" alt="loop" />
            </div>
          </Marquee>
        </div>

        <div className="collectionSwiper">
          <div>
            <div className="swiper-navigation-wrapper">
              <div className="swiper-btn-wrapper">
                <img
                  className="outer"
                  src={swiperLeftOuter}
                  srcSet={swiperLeftOuter}
                  loading="lazy"
                  alt="prev"
                />
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="prev inner"
                  src={swiperLeftInner}
                  srcSet={swiperLeftInner}
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="swiper-btn-wrapper">
                <img
                  className="outer"
                  src={swiperRightOuter}
                  srcSet={swiperRightOuter}
                  loading="lazy"
                  alt="next"
                />
                <motion.img
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="next inner"
                  src={swiperRightInner}
                  srcSet={swiperRightInner}
                  loading="lazy"
                  alt=""
                />
              </div>
            </div>
            <Swiper
              effect={"cards"}
              grabCursor={true}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: true,
              // }}
              modules={[EffectCards, Pagination, Autoplay]}
              onSlideChange={() => {
                setBeat1(false);
                setBeat2(false);
                setBeat3(false);
                setBeat4(false);
                setBeat5(false);
                setBeat6(false);
                setBeat7(false);
                setBeat8(false);
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy1}
                  srcSet={beatboy1}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio1} playing={beat1} setPlaying={setBeat1} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy2}
                  srcSet={beatboy2}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio2} playing={beat2} setPlaying={setBeat2} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy3}
                  srcSet={beatboy3}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio3} playing={beat3} setPlaying={setBeat3} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy4}
                  srcSet={beatboy4}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio4} playing={beat4} setPlaying={setBeat4} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy5}
                  srcSet={beatboy5}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio5} playing={beat5} setPlaying={setBeat5} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy6}
                  srcSet={beatboy6}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio6} playing={beat6} setPlaying={setBeat6} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy7}
                  srcSet={beatboy7}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio7} playing={beat7} setPlaying={setBeat7} />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="swiper-img"
                  src={beatboy8}
                  srcSet={beatboy8}
                  loading="lazy"
                  alt="Beatboy"
                />
                <Beat soundUrl={audio8} playing={beat8} setPlaying={setBeat8} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="marquee-desktop">
        <Marquee
          speed={75}
          gradient={false}
          pauseOnHover={false}
          pauseOnClick={false}
        >
          <div className="collection-marquee">
            <img src={drums} srcSet={drums} loading="lazy" alt="Drums" />
            <img src={bass} srcSet={bass} loading="lazy" alt="Bass" />
            <img src={guitar} srcSet={guitar} loading="lazy" alt="Guitar" />
            <img src={vocal} srcSet={vocal} loading="lazy" alt="Vocal" />
            <img
              src={eight0eight}
              srcSet={eight0eight}
              loading="lazy"
              alt="808"
            />
            <img src={loop} srcSet={loop} loading="lazy" alt="loop" />
            <img src={drums} srcSet={drums} loading="lazy" alt="drums" />
            <img src={bass} srcSet={bass} loading="lazy" alt="bass" />
            <img src={guitar} srcSet={guitar} loading="lazy" alt="guitar" />
            <img src={vocal} srcSet={vocal} loading="lazy" alt="vocal" />
            <img
              src={eight0eight}
              srcSet={eight0eight}
              loading="lazy"
              alt="808"
            />
            <img src={loop} srcSet={loop} loading="lazy" alt="loop" />
          </div>
        </Marquee>
      </div>
    </section>
  );
};

export default Collection;
