
import React, { useRef, useState, Component, useEffect } from "react";


// import soundUrl from "../assets/sound/beat1.wav";
import play from "../assets/play.png"
import pause from "../assets/pause.png"

/* Third party  ======================================================== */

import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";
import SwiperCore, { EffectFade, EffectCards, Pagination, Navigation  } from "swiper";
SwiperCore.use([EffectFade, EffectCards, Pagination, Navigation]);



const Beat = ({soundUrl , playing, setPlaying}) => {
  const useAudio = url => {
    const [audio] = useState(new Audio(url));
    
    audio.muted = true;
    audio.autoplay = false;
    audio.volume = 0;
    audio.loop = false;
    audio.play()
  
    const toggle = () => setPlaying(!playing);
  
    useEffect(() => {
      playing ? audio.volume = 1 : audio.volume = 0;
      playing ? audio.muted = false : audio.muted = true;
      playing ? audio.play() : audio.pause();
        
      },
      [playing]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  
    return [playing, toggle];
  };

  const [playing2, toggle] = useAudio(soundUrl);

  return (
   
            <div className="play-pause" onClick={toggle} >
              {playing2 ? 
                <img className="beat-controls" src={pause} srcSet={pause} loading="lazy" alt="pause" />
                    : 
                <img className="beat-controls" src={play} srcSet={play} loading="lazy" alt="play" />
              }
            </div>      
  );
};

export default Beat;
