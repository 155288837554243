
import React, { useRef, useState, Component, useEffect } from "react";
import DesktopNav from "./desktopNav";
import MobileNav from "./mobileNav";

/* Img  ======================================================== */

import Logo from "../assets/Logo.png";
import collection from "../assets/collection_li.png";
import story from "../assets/story_li.png";
import roadmap from "../assets/roadmap_li.png";
import team from "../assets/team_li.png";

/* Third party  ======================================================== */

import {Link} from 'react-scroll'


const Header = () => {

  const [show, setShow] = useState(null);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY === 0){
        console.log("ZERROOO");
        setShow(null);  
      }
      else if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
      }

   

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  

  return (
    <header className={`${show && 'hidden'}` + " " + "header"}>
        <div className="inner">

            {/* Logo  ======================================================== */}
            <img className="logo" src={Logo} srcSet={Logo} loading="lazy" alt="logo" />

            {/* Navigation  ======================================================== */}
            
            

            <div className="DesktopNav">
              <DesktopNav />
            </div>

            <div className="MobileNav">
              <MobileNav />
            </div>

            








        </div>
    </header>
  );
};

export default Header;
