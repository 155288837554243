import React, { useRef, useState, Component, useEffect } from "react";

/* Img  ======================================================== */

import banner from "../assets/Story-banner.png";

import title from "../assets/story_title.png";
import story1 from "../assets/story1.png";
import story2 from "../assets/story2.png";
import fighting from "../assets/fighting.png";

const Story = () => {

  return (
    <>
        {/* Banner 1  ======================================================== */}
        <img className="banner" src={banner} srcSet={banner} loading="lazy" alt="story banner" />

    <section className="story-section" >
         {/* Story Section  ======================================================== */}
        <div className="story-wrapper">
            <div className="story-img-wrapper">
              <img src={fighting} srcSet={fighting} loading="lazy" alt="beatboys fighting" />
            </div>

            <div className="story-var">
                <img className="title" src={title} srcSet={title} loading="lazy" alt="title" />
                <img className="description2" src={story2} srcSet={story2} loading="lazy" alt="description" />
            </div>
        </div>

    </section>
    </>
  );
};

export default Story;
