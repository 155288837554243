import React, { Component } from "react";
import { motion } from "framer-motion"
import {Link} from 'react-scroll'

/* Img  ======================================================== */

import Logo from "../assets/Logo.png";
import collection from "../assets/collection_li.png";
import story from "../assets/story_li.png";
import roadmap from "../assets/roadmap_li.png";
import team from "../assets/team_li.png";
import border from "../assets/border.png"
import ExitLeftOuter from "../assets/nav-exit-outer.png"
import ExitLeftInner from "../assets/nav-exit-inner.png"

import defaultTwitter from "../assets/social/default_twitter.png";
import defaultDiscord from "../assets/social/default_discord.png";
import defaultMagiceden from "../assets/social/default_magiceden.png";
import defaultOpensea from "../assets/social/default_opensea.png";

import hoverTwitter from "../assets/social/hover_twitter.png";
import hoverDiscord from "../assets/social/hover_discord.png";
import hoverMagicEden from "../assets/social/hover_magiceden.png";
import hoverOpensea from "../assets/social/hover_opensea.png";

class MobileNav extends Component {
    constructor() {
        super();
         this.state = {isNavOpen: false};
         this.handleNavClose = this.handleNavClose.bind(this);
      }

      handleNavClose() {
        this.setState({
          isNavOpen: false
        });
      }

    render() {


        return (
            <>
            <nav className="nav-mobile" role="navigation-mobile">
                <ul>
                    <li onClick={() => this.setState({ isNavOpen: true })}>
                    
                        <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.96875 42.8483C5.10299 41.9553 8.92128 40.6444 12.8015 40.3895C22.2956 39.7672 31.8199 39.6881 41.3268 39.278C45.0641 39.1165 48.7855 38.5877 52.5213 38.3613C54.9361 38.2141 57.6335 40.8787 57.7939 43.3644C57.8129 43.6732 57.8002 44.1656 57.6287 44.2796C56.0649 45.3039 54.5138 46.9996 52.8642 47.1247C38.2944 48.2314 23.7039 49.0705 9.11815 49.9508C6.76049 50.0949 4.33298 50.0791 2.91203 47.6013C2.21823 46.3901 1.82767 44.9889 0.96875 42.8483Z" fill="black"/>
                            <path d="M0.986328 21.1195C4.13781 20.6112 7.01781 19.7927 9.91209 19.742C20.6208 19.5552 31.3358 19.6549 42.0477 19.5726C45.7977 19.5441 49.5461 19.2512 53.2978 19.18C55.9428 19.1309 58.3036 19.8782 59.4324 22.698C59.6706 23.2901 59.5991 24.5631 59.2514 24.7784C57.4145 25.9073 55.4903 27.5222 53.5137 27.6568C38.3866 28.697 23.2467 30.0618 8.07835 28.1175C3.8949 27.5808 3.29953 27.2071 0.986328 21.1195Z" fill="black"/>
                            <path d="M0.986328 1.94173C4.13781 1.4335 7.01781 0.614957 9.91209 0.564292C20.6208 0.377467 31.3358 0.477212 42.0477 0.394882C45.7977 0.366384 49.5461 0.0734797 53.2978 0.00223263C55.9428 -0.0468484 58.3036 0.700453 59.4324 3.52025C59.6706 4.11239 59.5991 5.38534 59.2514 5.60066C57.4145 6.72953 55.4903 8.34446 53.5137 8.47904C38.3866 9.51924 23.2467 10.884 8.07835 8.93977C3.8949 8.40304 3.29953 8.02939 0.986328 1.94173Z" fill="black"/>
                        </svg>
                     
                    </li>
                </ul>
            </nav>

            <div className={`Nav ${this.state.isNavOpen ? 'Nav--open' : ''}`}>
                <div style={{position: "relative"}}>
                <div onClick={() => this.setState({ isNavOpen: false })} className="nav-header">
                <div className="exit-btn-wrapper">
                    <img className="" src={ExitLeftOuter} srcSet={ExitLeftOuter} loading="lazy" alt="exit" />
                    <motion.img  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} className="exit-inner" src={ExitLeftInner} srcSet={ExitLeftInner} loading="lazy" alt="exit" />
                </div>
                </div>
                {/* Navigation  ======================================================== */}
                <nav>
                    <ul>
                        <li><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="collection-section" spy={true} smooth={true}><img src={collection} srcSet={collection} loading="lazy" alt="collection" /></Link></li>
                        <li><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="story-section" spy={true} smooth={true}><img src={story} srcSet={story} loading="lazy" alt="story" /></Link></li>
                        {/* <li><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="roadmap-section" spy={true} smooth={true}><img src={roadmap} srcSet={roadmap} loading="lazy" alt="roadmap" /></Link></li> */}
                        <li><Link onClick={() => this.setState({ isNavOpen: false })} activeClass="active" to="team-section" spy={true} smooth={true}><img src={team} srcSet={team} loading="lazy" alt="team" /></Link></li>
                    </ul>
                </nav>

           

                </div>

                <footer className="nav-footer">
                    <div className="inner">
                        <a href="https://twitter.com/BeatBoysXYZ" target="_blank">
                            <img  src={defaultTwitter} onMouseOver={e => e.currentTarget.src = hoverTwitter} onMouseOut={e => e.currentTarget.src = defaultTwitter} loading="lazy" alt="twitter" />
                        </a>
                        <a href="#" target="_blank">
                            <img src={defaultDiscord} onMouseOver={e => e.currentTarget.src = hoverDiscord} onMouseOut={e => e.currentTarget.src = defaultDiscord} loading="lazy" alt="discord" />
                        </a>
                        <a href="#" target="_blank">
                            <img src={defaultMagiceden} onMouseOver={e => e.currentTarget.src = hoverMagicEden} onMouseOut={e => e.currentTarget.src = defaultMagiceden} loading="lazy" alt="magiceden" />
                        </a>
                        <a href="#" target="_blank">
                            <img src={defaultOpensea} onMouseOver={e => e.currentTarget.src = hoverOpensea} onMouseOut={e => e.currentTarget.src = defaultOpensea} loading="lazy" alt="opensea" />
                        </a>
                    </div>
                </footer>
            </div>

            
            </>
        );
    }
}

export default MobileNav;
