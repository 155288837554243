
import React, { useRef, useState, Component, useEffect } from "react";

/* Img  ======================================================== */

import Logo from "../assets/Logo.png";
import collection from "../assets/collection_li.png";
import story from "../assets/story_li.png";
import roadmap from "../assets/roadmap_li.png";
import team from "../assets/team_li.png";

/* Third party  ======================================================== */

import {Link} from 'react-scroll'

const DesktopNav = () => {


  return (

            <>
            {/* Navigation  ======================================================== */}
            <nav>
                <ul>
                    <li><Link activeClass="active" to="collection-section" spy={true} smooth={true}><img src={collection} srcSet={collection} loading="lazy" alt="collection" /></Link></li>
                    <li><Link activeClass="active" to="story-section" spy={true} smooth={true}><img src={story} srcSet={story} loading="lazy" alt="story" /></Link></li>
                    {/* <li><Link activeClass="active" to="roadmap-section" spy={true} smooth={true}><img src={roadmap} srcSet={roadmap} loading="lazy" alt="roadmap" /></Link></li> */}
                    <li><Link activeClass="active" to="team-section" spy={true} smooth={true}><img src={team} srcSet={team} loading="lazy" alt="team" /></Link></li>
                </ul>
            </nav>
            </>
  );
};

export default DesktopNav;
